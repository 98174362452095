import { computed, defineComponent, ref, unref } from "vue";
import { useRouter } from "vue-router";
import { Toast } from "vant";
import { message } from "ant-design-vue";
import { PassRegexp } from "@/hooks/component/loginValidate";
import { resetPassword } from "@/apis";
export default defineComponent({
    name: "AuthPasswordSet",
    components: {},
    setup() {
        const { currentRoute, push, back } = useRouter();
        const password = ref("");
        // 来自哪
        const from = computed(() => unref(currentRoute).query.from ?? "");
        // 标题复用
        const title = computed(() => from.value === "reset" ? "重新设置密码" : "设置密码");
        // 验证码
        const verifycode = computed(() => unref(currentRoute).query.verifycode ?? "");
        // 手机号
        const mobile = computed(() => unref(currentRoute).query.mobile ?? "");
        // 微信code码
        const wechatcode = computed(() => unref(currentRoute).query.wechatcode ?? "");
        const haveuser = computed(() => unref(currentRoute).query.haveuser ?? "");
        const token = computed(() => unref(currentRoute).params.token ?? "");
        // 控制验证码下一步按钮是否可以点击
        const pwdDisabled = computed(() => password.value !== "");
        // 控制验证码下一步按钮类型
        const pwdBtnType = computed(() => pwdDisabled.value ? "primary" : "default");
        // 跳转创建企业
        function jumpCorpCreate() {
            push({
                name: "AuthCorpCreate",
                query: {
                    mobile: mobile.value,
                },
                params: {
                    password: password.value,
                    token: token.value,
                    haveuser: haveuser.value,
                },
            });
        }
        // 跳转登录
        function jumpLogin() {
            push({
                name: "AuthLogin",
                query: {
                    mobile: mobile.value,
                    from: from.value,
                    wechatcode: wechatcode.value
                },
            });
        }
        // 重置密码
        function identifyReset() {
            resetPassword({
                mobile: mobile.value,
                verifycode: verifycode.value,
                password: password.value,
            }).then((res) => {
                if (res.errcode === 0) {
                    Toast("密码重置成功\n请重新登录");
                    setTimeout(() => {
                        jumpLogin();
                    }, 500);
                }
                else {
                    Toast(res.errmsg || "密码重置失败");
                    if (res.errcode === 201101) {
                        setTimeout(() => {
                            back();
                        }, 500);
                    }
                }
            });
        }
        // 设置密码
        function handleSubmit() {
            if (!PassRegexp.test(password.value)) {
                message.error("密码6-16位含数字和字母");
                return;
            }
            if (from.value === "reset") {
                identifyReset();
            }
            else {
                jumpCorpCreate();
            }
        }
        return {
            title,
            password,
            pwdDisabled,
            pwdBtnType,
            handleSubmit,
        };
    },
});
